import React from "react";
import {Link} from "gatsby";
import PropTypes from "prop-types";

const SearchResults = ({ results, searchString = "" }) => (
	<ul className="flex-item resultSearch">
		{results.map((page) => {
			// find the piece of text on the document where the searchString appears and trim a bit before
			// and after to show an overview of where it appears
			let n = page.tags.toLowerCase().search(searchString.toLowerCase());
			n = n && n > 50 ? n : 50;
			const str = page.tags.slice(n-50, n+50).replace(/searchString.toLowerCase()/);

			return(
				<li key={page.id}>
					<Link to={"/" + page.path.split("/content")[1].replace(".md", "")}>{page.title}</Link>
					{": " + str}
				</li>
			)}
		)}
	</ul>
);

SearchResults.Proptypes = {
	results: PropTypes.array
};

export default SearchResults;