import React from 'react'
import PropTypes from 'prop-types'
import { Layout, Row, Col, Anchor, Menu } from 'antd'
import DocsNav from '../DocsNav'
import '../../styles/reset.less'
import SiteHeader from "../SiteHeader";
import SearchResults from "../SiteSearch/search-results";
import { withAuth0 } from '@auth0/auth0-react';

const { Content, Sider } = Layout;

class SiteLayout extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            searchResults: [],
            searchString: ""
        };
    }

    showSearchResults = (results, searchString) => {
        this.setState({
            searchResults: results,
            searchString: searchString
        });
    };

    render() {
        const {
            isAuthenticated
        } = this.props.auth0;
        const { children, sideBar, location, external } = this.props;
        const renderSidebar = sideBar !== "" && isAuthenticated;

        return (
            <Layout className="strands-layout">
                    <SiteHeader location />
                    <Layout className="main-portal-content">
                        {renderSidebar
                            && (<Sider breakpoint="md" collapsedWidth="0" width={300}>
                                <Row type="flex" justify="center" align="middle" style={{ marginBottom: '2rem' }}></Row>
                                <Anchor offsetTop={85}>
                                    <DocsNav location={location} sideBar={sideBar} onSearchResults={this.showSearchResults} />
                                </Anchor>
                            </Sider>)}
                        <Layout style={{ minHeight: 'calc( 100vh )', }}>
                            {this.state.searchResults.length > 0 ? (
                                <Content className="sf-n-platform" style={{ margin: '0' }}>
                                    <div className="doc-content" style={{ paddingTop: 24, minHeight: 360, marginLeft: 24 }}>
                                        <h1>Search Results</h1>
                                        <SearchResults results={this.state.searchResults} searchString={this.state.searchString} />
                                    </div>
                                </Content>
                            ) : (
                                    <Content className="sf-n-platform" style={{ margin: '0' }}>
                                        <div className={external ? "doc-content doc-content-external" : "doc-content"}
                                            style={{ paddingTop: 24, minHeight: 360, marginLeft: 24 }}>
                                            {children}
                                        </div>
                                    </Content>
                                )}
                        </Layout>
                    </Layout>
            </Layout>
        )
    };
}

SiteLayout.defaultProps = {
    location: null,
    sideBar: "",
    external: false
}

SiteLayout.propTypes = {
    children: PropTypes.node,
    location: PropTypes.object,
    sideBar: PropTypes.string,
    external: PropTypes.bool
}

export default withAuth0(SiteLayout)
