import React from 'react'
import { Link } from 'gatsby'
import StrandsLogo from '../../images/Logo_Strands_White_Crif.svg'
import {
  Layout,
  Row, Col, Anchor, Menu
} from 'antd'

const Logo = ({ width = 106, height = 106, margin = '1rem' }) => (
  <Link to="/" className="logo"
    style={{
      display: "flex"
    }}>

    <div style={{ marginRight: "20px" }}>
      <StrandsLogo width={width} height={height} />
    </div>

    <div
      style={{
        borderLeft: "2px solid white",
        height: "46px",
        width: "0px",
        opacity: 0.42,
        marginTop: "3px"
      }} />

    <div style={{
      color: "#00a7a0",
      fontWeight: "bold",
      opacity: 1,
      paddingLeft: "20px",
      marginTop: "13px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "18px"
    }}>
      DEVELOPERS
    </div>

  </Link >
)

export default Logo;