import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'gatsby'
import { Layout, Menu } from 'antd'
import Logo from '../Logo'
import { withAuth0 } from '@auth0/auth0-react';

const { Header } = Layout;

class SiteHeader extends React.Component {
    constructor(props) {
        super(props);
    }

    logUser = (isAuthenticated, loginWithRedirect, logout) => () => {
        isAuthenticated ? logout({ returnTo: window.location.origin + "/app" }) : loginWithRedirect();
    }

    getHeaderLinks(isAuthenticated, loginWithRedirect, logout) {
        return isAuthenticated ? [
            /* {key: 'search', name: 'Search', link: '/search'}, */
            { key: 'documentation', name: 'Documentation', link: '/app/docs' },
            { key: 'api_reference', name: 'API Reference', link: '/app/api_reference' },
            { key: 'ui_kit', name: 'UI Kit', link: '/app/ui_kit' },
            { key: 'tutorials', name: 'Tutorials', link: '/app/tutorials' },
            { key: 'faq', name: 'FAQ', link: '/faq' },
            { key: 'log_user', name: 'Logout', onClick: this.logUser(isAuthenticated, loginWithRedirect, logout), className: 'btn-login' },
        ] : [
                { key: 'log_user', name: 'Login', onClick: this.logUser(isAuthenticated, loginWithRedirect, logout), className: 'btn-login' },
            ];
    }

    render() {
        const {
            isAuthenticated,
            loginWithRedirect,
            logout
        } = this.props.auth0;
        const { location } = this.props;
        const selectedKey = location && location.pathname !== "/" ? location.pathname : "documentation";
        const headerMenu = this.getHeaderLinks(isAuthenticated, loginWithRedirect, logout);

        return (
            <Header className="header" style={{ paddingTop: "4px" }}>
                <div className="container"
                    style={{
                        display: "flex",
                        justifyContent: "space-between"
                    }}>
                    <Logo width={190} height={50} />
                    <Menu
                        theme="dark"
                        mode="horizontal"
                        defaultSelectedKeys={['2']}
                        selectedKeys={[selectedKey]}
                        style={{ lineHeight: '70px' }}>
                        {headerMenu.map(item => {
                            return (
                                <Menu.Item key={item.key} onClick={item.onClick || null}>
                                    {item.link ? (
                                        <Link to={item.link} className={item.className}>{item.name}</Link>
                                    ) : (
                                            <div className={item.className}>{item.name}</div>
                                        )}
                                </Menu.Item>
                            );
                        })}
                    </Menu>
                </div>
            </Header>
        );
    }
}

SiteHeader.propTypes = {
    siteTitle: PropTypes.string,
}

SiteHeader.defaultProps = {
    siteTitle: '',
}

export default withAuth0(SiteHeader)

