import React, { Component } from 'react'
import { navDocs, navTutorials } from '../../utils/nav'
import { graphql, navigate, StaticQuery } from 'gatsby'
import { Menu, Icon } from 'antd'
import NavBarSearch from '../../components/NavBarSearch'
import '../../styles/reset.less'
const { SubMenu, ItemGroup } = Menu;

class DocsNav extends Component {

    constructor(props) {
        super(props);

        this.state = {
            navigationMenu: this.getNavigationMenu(this.props.sideBar || "")
        };
    }

    getOpenKey = (navigationMenu, path) => {
        if (!path) return;

        const list = navigationMenu.map(item => {
            const subs = item.items.filter(subItem => {
                return path.includes(subItem.directory)
            });

            if (subs.length) {
                return subs[0].directory;
            }
        });
        return list.filter((el) => {
            return el != null;
        })

    };

    getNavigationMenu = (sideBar) => {
        if (sideBar === "tutorials") return navTutorials;
        else return navDocs;
    };

    itemInSearch = (item, results) => {
        return results.find((result) => {
            return result.title.toLowerCase() === item.toLowerCase();
        });
    };

    filterSearch = (results, searchString) => {
        const defaultMenu = this.getNavigationMenu(this.props.sideBar || "");
        let filteredMenu;

        // we execute the callback function passed as prop
        this.props.onSearchResults(results, searchString);

        // If we do not found any result we restore the default menu with all entries
        if (!results.length) {
            this.setState({
                navigationMenu: defaultMenu
            });
            return;
        }

        // otherwise we reduce all menus and submenus with the search results
        filteredMenu = defaultMenu.reduce((acc, menu) => {
            const filteredMenuItem = Object.assign({}, menu);
            filteredMenuItem.items = menu.items.reduce((acc2, submenu) => {
                const filteredSubMenuItem = Object.assign({}, submenu);
                if (submenu.items) {
                    filteredSubMenuItem.items = submenu.items.filter((submenuItem) => {
                        return this.itemInSearch(submenuItem.title, results);
                    });
                    if (filteredSubMenuItem.items.length) acc2.push(filteredSubMenuItem);
                }
                else {
                    if (this.itemInSearch(submenu.title, results)) acc2.push(filteredSubMenuItem)
                }
                return acc2;
            }, []);
            if (filteredMenuItem.items.length) acc.push(filteredMenuItem)
            return acc;
        }, []);


        this.setState({
            navigationMenu: filteredMenu
        })
    };

    render() {
        const { location } = this.props;
        const pathname = location && location.pathname ? location.pathname : null;

        return (
            <div>
                {/* <StaticQuery
                    query={graphql`
                query SearchIndexQuery2 {
                  siteSearchIndex {
                    index
                  }
                }
              `}
                    render={data => (
                        <header className="flex-item">
                            <p>Search:</p>
                            <NavBarSearch searchIndex={data.siteSearchIndex.index} onSearch={this.filterSearch} />
                        </header>
                    )}
                /> */}
                <Menu
                    theme="dark"
                    mode="inline"
                    defaultSelectedKeys={['getting-started/welcome']}
                    selectedKeys={(pathname && pathname !== '/') ? [pathname.substr(1)] : []}
                    style={{ height: '100%' }}
                >
                    {this.state.navigationMenu.map( (item, i) => {
                        return item.hidden?"":(

                            <ItemGroup key={item.id + i} title={<span><Icon type={item.icon} />{item.title}</span>}>
                                {item.items.map( subItem => {
                                    // No subItems. It's a page.
                                    if(!subItem.items){
                                        return subItem.hidden?"":<Menu.Item key={ item.directory + '/' + subItem.id } onClick={() => { navigate( '/' + item.directory + '/' + subItem.id ) }}>{subItem.title}</Menu.Item>;
                                    }

                                    // Has items and is expandable. It's a SubMenu
                                    return subItem.hidden?"":(
                                        <SubMenu key={subItem.id+i} title={<span>{subItem.title}</span>}>
                                            {subItem.items.map( subSubitem => {
                                                return subSubitem.hidden?"":<Menu.Item key={subItem.directory + '/' + subSubitem.id } onClick={() => { navigate( '/' + subItem.directory + '/' + subSubitem.id ) }}>{subSubitem.title}</Menu.Item>;
                                            })}
                                        </SubMenu>
                                    );
                                })}
                            </ItemGroup>
                        );
                    })}
                </Menu>
            </div>
        );
    }
}

export default DocsNav;
